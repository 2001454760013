import {BsFillMoonStarsFill} from 'react-icons/bs'
import {
    AiFillTwitterCircle, 
    AiFillLinkedin, 
    AiFillGithub
  } from 'react-icons/ai'
import deved from './images/dev-ed-wave.png'
import design from './images/design.png'
import code from './images/code.png'
import consulting from './images/consulting.png'
import web1 from './images/web1.png'
import web2 from './images/web2.png'
import web3 from './images/web3.png'
import web4 from './images/web4.png'
import web5 from './images/web5.png'
import web6 from './images/web6.png'

import { useState } from 'react';


function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <div  className={darkMode? "dark": ""}>
      <main className='bg-white dark:bg-gray-900'> {/*bg is white, padding left&right is 10px*/}
      <section className=' min-h-screen px-3 md:px-20 lg:px-40'>
        <nav className='py-10 mb-12 flex justify-between'>   {/* padding top and bottom, margin-bottom of 12 px, flex display, justify contents to space between*/}
          <h1 className='text-xl font-burtons dark:text-white cursor-pointer'>developedByUzezi</h1>       {/*font size should be extra large*/}
          <ul className='flex items-center'>    {/* aligning items to the center*/}
            <li>
              <BsFillMoonStarsFill
                onClick={() => setDarkMode(!darkMode)} 
                className='text-2xl cursor-pointer dark:text-white'/>
            </li>    {/*cursor should be pointer*/}
            <li>
              <a 
                href="#resume"
                className='bg-gradient-to-r from-cyan-500 to-teal-500
                 text-white px-4 py-2 rounded-md ml-4 hover:text-slate-800'
              >
                Resume
              </a>
            </li>
          </ul>
        </nav>

        <div className='text-center p-10'>
          <h2 className='text-5xl py-2 text-teal-600 font-medium md:text-6xl'>Godfrey Offeh</h2>
          <h3 className='text-2xl py-2 md:text-3xl dark:text-white'>Front End Developer</h3>
          <p 
            className='text-md py-5 leading-8
             text-gray-800 md:text-xl max-w-lg mx-auto dark:text-white'>
             Freelancer providing services for web development and design content needs. Join me down below and let us get you started!
          </p>
        </div>

        <div 
          className='text-5xl flex justify-center gap-16 
          py-3 text-gray-600'
        >
          <a 
            href="https://twitter.com/pypper_" 
            target='_blank' rel="noreferrer" 
            className='transition duration-200 ease-in hover:scale-125 hover:bg'
          >
            <AiFillTwitterCircle />
          </a>
          <a 
            href="https://www.linkedin.com/m/in/godfrey-offeh-422992138" 
            target='_blank' rel="noreferrer" 
            className='transition duration-200 ease-in hover:scale-125'
          >
          <AiFillLinkedin />
          </a>
          <a 
            href="https://github.com/Pyppersama" 
            target='_blank' rel="noreferrer" 
            className='transition duration-200 ease-in hover:scale-125'
          >
          <AiFillGithub />
          </a>
        </div>

        <div 
          className='relative mx-auto mt-20 mb-10 bg-gradient-to-b 
          from-teal-500 rounded-full w-80 h-80 overflow-hidden
          md:w-96 md:h-96'
        >
          <img 
          alt=''
            src={deved}
            layout='fill'
            objectfit='cover'
          />
        </div>
      </section>

      <section className='px-3 py-10 my-10 md:px-20 lg:px-40 bg-slate-100 dark:bg-slate-800 '>
        <div>
          <h3 className='text-3xl text-center py-1 dark:text-white'>Services I offer</h3>
          <p className='text-md text-center py-2 leading-8 text-gray-800 dark:text-white
                        md:text-xl  mx-auto'>
            As a front-end <span className="text-teal-500"> developer </span>
             and <span className="text-teal-500"> designer</span>,
            I offer from a wide range of services, including
             web development, programming and designing
          </p>
        </div>

        <div className='lg:flex gap-10'>
          <div className='text-center shadow-lg p-10 rounded-xl my-10 bg-white'>
            <img 
            alt=''
              src={design}
              width={200}
              height={200}
              className='m-auto'
            />
            <h3 className='text-lg font-semibold pt-8 pb-2'>User Interface (UI) Design</h3>
            <p className='py-2'>Creating elegant UI designs suited for your needs 
              following core design theory.
            </p>
            <h4 className='py-4 text-teal-600 cursor-pointer transition duration-200 ease-linear hover:scale-110'>
              UI DESIGN TOOLS I USE
            </h4>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Adobe Photoshop
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Figma
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Adobe XD
            </p>
          </div>
          
          <div className='text-center shadow-lg p-10 rounded-xl my-10 bg-white '>
            <img 
              alt=''
              src={code}
              width={100}
              height={100}
              className='m-auto'
            />
            <h3 className='text-lg font-semibold pt-8 pb-2 '>Web Development</h3>
            <p className='py-2'>Do you have an idea for your next great website? Let us make it a reality.
            </p>
            <h4 className='py-4 text-teal-600 cursor-pointer transition duration-200 ease-linear hover:scale-110'>
              WEB DEVELOPMENT TOOLS I USE
            </h4>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Visual Studio Code
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Git
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              GitHub
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              npm (Node Package Manager)
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Chrome DevTools
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Netlify
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Cloudflare
            </p>
          </div>
          
          <div className='text-center shadow-lg p-10 rounded-xl my-10 bg-white'>
            <img 
              alt=''
              src={consulting}
              width={300}
              height={300}
              className='m-auto'
            />
            <h3 className='text-lg font-semibold pt-8 pb-2'>Responsive Designs</h3>
            <p className='py-2'>Creating elegant designs and functionalities that look appealing and fluid on all devices.
            </p>
            <h4 className='py-4 text-teal-600 cursor-pointer transition duration-200 ease-linear hover:scale-110'>
              RESPONSIVE DESIGN TOOLS I USE
            </h4>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              BootStrap
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Tailwind CSS
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              CSS Grid System
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Chakra UI
            </p>
            <p className='text-gray-800 py-1 cursor-pointer transition 
              duration-200 ease-linear hover:font-bold hover:bg-slate-200 rounded-md'>
              Media Queries
            </p>
          </div>
        </div>
      </section>

      <section id='resume' className='px-3 md:px-20 lg:px-40'>
        <div className='mt-10'>
          <h3 className='text-3xl py-1 dark:text-white'>Portfolio</h3>
          <p className='text-md py-2 leading-8 text-gray-800 dark:text-white md:text-xl'>
          Welcome to my portfolio showcasing a collection of projects that reflect my passion 
          for web <span className='text-teal-500'>development</span> and 
          <span className='text-teal-500'> design</span>. Through these projects, I have harnessed my skills to create dynamic and user-centered digital experiences. 
          </p>
          <p className='text-md py-2 leading-8 text-gray-800 dark:text-white md:text-xl'>
          My goal is to bring ideas to life and elevate user interactions. Explore the various 
          projects below to witness the synergy of coding expertise, design sensibilities, and innovation.
          </p>
        </div>
        <div className='flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap'>
            <div className='basis-1/3 flex-1 border border-cyan-300 rounded-lg relative'>
              <img src={web1} 
                alt=''
                className='rounded-lg object-cover' 
                width={'100%'} 
                height={'100%'} 
                layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://617738f2.real-estate-react-cg6.pages.dev/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>A Real Estate Website</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                    The real estate website, built with React, 
                    features a dynamic interface comprising of various components. 
                    It seamlessly guides users through property listings, agency affiliations, 
                    value propositions, and contact details.
                  </p>
                </a> 
              </div>
            </div>
            <div className='basis-1/3 flex-1 border border-cyan-300 rounded-lg relative'>
              <img src={web2} 
                alt=''
                className='rounded-lg object-cover' 
                width={'100%'} 
                height={'100%'} 
                layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://pypper-tech-shop.pages.dev/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>A React Online Store</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                    It features an e-commerce functionality with product browsing and purchasing. 
                    It utilizes react-router-dom for client-side routing, and state management is handled through 
                    ShopContextProvider. The interface outlines the a basic structure of an e-commerce app.
                  </p>
                </a> 
              </div>
            </div>
            <div className='basis-1/3 flex-1 border border-cyan-300 rounded-lg relative'>
              <img src={web3} 
                alt=''
                className='rounded-lg object-cover' 
                width={'100%'} 
                height={'100%'} 
                layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://pyppersama.github.io/Team_5-_Terra_Developers/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>Terra Cloned Website</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                  This collaborative project involved a team creating a responsive clone of the 
                  "SideHustle" website using Git and GitHub Pages for collaboration. The project 
                  utilized external resources like Font Awesome, Google Fonts, and Bootstrap to enhance 
                  styling, fonts, and icons.
                  </p>
                </a> 
              </div>
            </div>
            <div className='basis-1/3 flex-1 border border-cyan-500 rounded-lg relative'>
              <img src={web4} 
                  alt=''
                  className='rounded-lg object-cover' 
                  width={'100%'} 
                  height={'100%'} 
                  layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://react-pypper-pizza.pages.dev/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>A Pizza Resturant Website</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                    This is a restaurant website utilizing React Router for navigation and rendering different pages. It website leverages 
                    React, React Router for routing, and CSS for styling, including background images to create an engaging user experience.  
                  </p>
                </a> 
              </div>
            </div>
            <div className='basis-1/3 flex-1 border border-cyan-500 rounded-lg relative'>
              <img src={web5} 
                alt=''
                className='rounded-lg object-cover' 
                width={'100%'} 
                height={'100%'} 
                layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://pyppersama.github.io/online-code-editor/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>An Online Code Editor</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                  The Online Code Editor is a web app enabling users to write and execute HTML, 
                  CSS, and JavaScript code in their browser. It offers separate editors, 
                  tab navigation, and a "Run" button for live results. Using HTML, CSS, JavaScript, and jQuery.
                  </p>
                </a> 
              </div>
            </div>
            <div className='basis-1/3 flex-1 border border-cyan-500 rounded-lg relative'>
              <img src={web6} 
                alt=''
              className='rounded-lg object-cover' 
              width={'100%'} 
              height={'100%'} 
              layout='responsive'/>
              <div className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-cyan-900 bg-opacity-80'>
                <a href="https://pyppersama.github.io/simple-react-calculator/" target="_blank" rel="noreferrer">
                  <h3 className='text-lg text-white text-center mb-2 md:text-3xl md:mb-5'>A Simple React Calculator</h3>
                  <p className='text-white leading-6 text-md px-5 text-center md:text-lg md:leading-8 md:px-10'>
                  The provided React application comprises a simple calculator with navigation functionalities.  
                  The calculator's functionalities are built within the Calculator component, showcasing the use of 
                  React Router and React's core features for building a navigation-driven calculator application.
                  </p>
                </a> 
              </div>
            </div>
        </div>
      </section>
    </main>
   </div>
  );
}

export default App;
